@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.gIyphu {
  background-color: #fff;
  height: 100%;
  left: 0 !important;
  max-width: 272px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: left 500ms ease-in-out !important;
  transition: left 500ms ease-in-out !important;
  width: 100%;
  z-index: 10;
}


.ketOhX {
    background: #000;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out;
    width: 100%;
    z-index: -1;
  }
  .ifMgmj {
    background-color: #fff;
    height: 100%;
    left: inherit;
    max-width: 300px;
    overflow-y: auto;
    position: fixed;
    right: -100%;
    top: 0;
    -webkit-transition: left 500ms ease-in-out !important;
    transition: left 500ms ease-in-out !important;
    width: 100%;
    z-index: 10;
  }
  .div-uu {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  

  .lneEfO {
    background: #000;
    height: 100%;
    left: 0;
    opacity: 0.8 !important;
    position: fixed;
    top: 0;
    -webkit-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out;
    width: 100%;
    z-index: 1;
}

.accordion__button {
  background-color: transparent !important;
  color: white !important;
  cursor: pointer !important;
  padding: 0px !important;

  width: 100%;
  text-align: left;
  align-items: center;
  border: none !important;
}

.accordion__panel {
  padding:0px !important;
  /* margin: 10px ; */
  animation: fadein 0.35s ease-in;
}

.accordion {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 2px;
}


.accordion__button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-size: 0.9rem;

}
.tab-acc{
 margin-top: 20px;
 padding:10px;
}
.accordion {
display: flex;
flex-direction: column;
gap: 1rem;
padding: 10px 0px;

}


.accordion__item + .accordion__item {

border: none !important;

}


/* *::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 20px;
  
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:black; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:rgba(0, 0, 0, 0.726) ; 
}

.head-font{
  /* text-align: center; */
  font-family: 'Luckiest Guy', cursive;
  font-size: 24px;
  color:black;
}
.hIHipY {
left:0 !important; 
right: 0 !important;
-webkit-transition: left 500ms ease-in-out !important;
transition: left 500ms ease-in-out !important;
}
.head-pvt {

  -webkit-transition: left 500ms ease-in-out !important;
  transition: left 500ms ease-in-out !important;
}
.hIHipY {

  /* top: 72px !important; */
}

/* Mobile-menu */


.side-menu-h1>h1{
  font-size: 14px;
  font-weight: 400;
}
.side-menu-h1{
  display: flex;
  padding: 10px;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
}

.head-mobile-menu{
  /* width: 200px; */
  padding: 30px;
}
ul{
  list-style-type: none;
  text-decoration: none;
}
a{
  text-decoration: none;
list-style-type: none;

}
/* .sc-bqWxrE {
-webkit-transition: left 500ms ease-in-out !important;
transition: left 500ms ease-in-out !important;
} */
.ul-menu-mobile{

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.hIHipY {

  z-index: 100 !important;
  background:#bc0000 !important;
  color: white;

}
.user-name-mobile-menu>svg{

  font-size: 40px;
}
.user-m>h1{
  font-size: 17px;
  font-weight: 400;

}
.user-name-mobile-menu{
display: flex;
gap: 0.5rem;
align-items: center;

}
.mobile-k-m{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.link-k{
  color: white;

  font-size: 14px;
  font-weight: 400;
}
.close-mobile-menu{
  cursor: pointer;
}
.open-tab-mob{
  font-size: 25px;
  color: black;
}

.mobile-menu-nav{
display: none;
}
.sidebar-home-d {
  /* width: 96px; */
  /* position: fixed; */
}
.gkgFDi {
  height: 0px !important;
  width: unset !important;
  color:unset !important;
   margin: unset !important; 
}
.fWwMBW {
  padding: 6px 16px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: white;
}

@media only screen and (max-width:600px) {

.sidebar-home-d{
display: none;
}
.mobile-menu-nav{
  flex-direction: row-reverse;
  display:flex !important;
  padding: 10px;
  justify-content: space-between;
  }

  .side-c
  {
display:none !important ;
  }

}