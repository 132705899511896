.table-a1 {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  
  .table-a1>caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
 .table-a1-tr{
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }
  .table-a2-tr{
    background-color: #f8f8f8;
    /* border: 1px solid #ddd; */
    padding: .35em;
  }
  .table-a1-th{

        padding: .625em;
        text-align: center;

  }
  .table-a1-td {
    padding: .625em;
    text-align: center;
  }
  
  .table-a1-th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  .bg-contact {
    background-image: url("../../../assests/Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  
  @media screen and (max-width: 1000px) {
    .table-a1 {
      border: 0;
    }
  
    .table-a1>caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      /* margin-bottom: .625em; */
    }
    
   .table-a1-td{
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right !important;
    }
    
    .table-a1-td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .table-a1-td:last-child {
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  }

  
  .contact-h1 {
    font-size: 20px;
  }
  .contact-h2 {
    font-size: 20px;
  }
  .contact-h3 {
    font-size: 20px;
  }
  .contact-section1 {

    width: 100%;
    display: flex;
    justify-content: center;
   
  }
  .head-pp {
    width: 90%;
  }
  .view-btn1{
    padding: 8px 25px;
    color: white;
    background: #bc0000;
    border-radius: 8px;
    border: none;
  }
  .mark-as-btn{
    padding: 8px 25px;
    color: white;
    background: #bc0000;
    border-radius: 30px;
    border: none;
  }
 

  
.close-sv-d{
    display: flex;
    justify-content:flex-end;
    padding: 5px 10px;
}



    .table-a2 {
      border: 0;
    }
  
    .table-a>caption {
      font-size: 1.3em;
    }
    
    .table-a2>thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    .table-a2-tr {
      border-bottom: 1px solid #ddd;
      display: block;
    }
    
    .table-a2-td{
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
   .table-a2-td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
   .table-a2-td:last-child {
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    text-align: center;
    }
    .table-a2 {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }
    .table-a2-td {
        padding: 0.625em;
       
    }
    .table-a2-th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

.modal-bb1{

    padding: 10px 0px;
    display: flex;
    justify-content: center;
}

.modal-content-h2{
  width: 600px;
  /* height: 70vh; */
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 70px;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

@media only screen and (max-width: 600px){
.modal-content-h2 {
    width: 300px !important;
}
}
.contact-span1{
  font-size: 17px;

}
.view-bt1{
  padding: 8px 25px;
  color: white;
  background: #bc0000;
  border-radius: 8px;
  border: none;
}
.contact-d-h{
  padding: 20px 0px;
  color: #bc0000;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  
}