.log-h1{
    color: #bc0000;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}
.input-log{

    padding: 15px 20px;
    background: #d3d3d357;
    width: 270px;

    border: none;
    border-radius: 30px;
}
.log-bb1{
    margin-top: 20px;
    color: white;
    background-color: #bc0000;
    padding: 9px 24px;
    font-size: 15px;
    border: none;
    border-radius: 15px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    cursor: pointer;
}
.log-form{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.log-fgg{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.logo-pp5>img{

    width: 250px;
}
.logo-pp5{

    display: flex;
    justify-content: center;
    align-items: center;
}
.log-form-p{

    padding: 100px 0px;
}
.log-bg{
    background-image: url('../../../assests/Img/20210204_a547.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.password-icon2{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.passworld-hide-show{
    position: relative;
}