.reply-bg{
    background-image: url('../../../assests/Img/20210204_a547.jpg');
    background-position: center;
    background-size: cover;
    /* min-height: 618px; */
}

.border-reply{

    border: 1px solid #b8acac;
    width: 90%;
    border-radius: 5px;
}
.reply-main-data{
    padding: 100px 0px 30px 0px;
    display: flex;
    margin-bottom: 151px;
    justify-content: center;
    /* flex-direction: column; */
    /* align-items: center; */
    /* padding: 7% 4%; */
}
.side-reply{
    display: flex;
    width: 100%;
}
.reply-data-s{
    width: 90%;
}
.data-reply{
    display: flex;
    justify-content: center;
    width: 100%;

}
.side-r-btns{
    padding: 4% 4%;
    display: flex;
    justify-content: center;
width: 10%;
}
.btn-reply-s{

    padding: 8px 25px;
    color: white;
    background: #bc0000;
    border-radius: 30px;
    border: none;
}
.btn-reply-1{

    padding: 11px 40px;
 
    color:black;
    background:white;
    border-radius: 30px;
    border: none;
}
.btn-reply-1:hover{
    color:white;
    background:#bc0000;
}
.btn-side{
    /* position: fixed;
    display: flex;
    flex-direction: column;
    gap: 1rem; */
}
.btn-reply-2{

    padding: 11px 40px;
 
    color:black;
    background:white;
    border-radius: 30px;
    border: none;
}
.btn-reply-2:hover{
    background:#bc0000;

    color:white;
}
@media only screen and (max-width: 850px) {
.data-reply {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.side-r-btns {

    width: auto;
}
.reply-data-s {
    width: 100%;
}
}
.head-reply-comment{

    padding: 10px 20px;

}
.btn-head-comm{

    padding: 11px 17px;
    color: black;
    background: white;
    border-radius: 30px;
    /* border: none; */
    border: 1px solid;
}
.btn-head-comm:hover{
    background:#bc0000;
    color:white;
}
.reply-h1{
    color: #444444;

    font-size: 21px;
    width: fit-content;
    padding: 15px 0px ;
}

.span-2-reply{
    color: #a3a5a9;
    padding: 10px 0px;
    font-size: 14px;
}
.name-hov{
    color: #444444;
    cursor: pointer;;

}
.name-hov:hover{
    color: #bc0000;

}
.para-1-reply{
    color: #444444;
    padding: 10px 0px;
}
.para-1-reply>p{

line-height: 1.5;
}
.reply-count>h1{
    color: #444444;
    padding: 10px 0px;

    font-size: 15px;
}
.reply-count{
    padding: 10px 20px;
}
.user-reply-dp{

    width: 52px;
    border-radius: 50px;
}
.another-reply-1{

    padding: 10px 20px;
}
.user-dp-time-member{

    display: flex;
    gap: 1rem;
}
.user-data-t>h1{
    color: #444444;
    font-size: 20px;
}
.user-data-t>p{
    color: #444444;
    font-size: 14px;
}
.user-data-t{

    display: flex;
    flex-direction: column;
    gap: 3px;
    
}
.section-reply-p2{

    font-size: 15px;
    margin-top: 12px;
}

@media only screen and (max-width: 870px) {

.btn-side {
    /* position: unset;
    display: flex;
    flex-direction: column;
    gap: 1rem;   */

}
.data-reply {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
}

.reply-btn-count{

    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 950px) {

.reply-main-data{
    /* padding: 30px 0px 0px 0px; */
}
}
.reply-h1-y{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;

}
.reply-h2-y{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    color: #a3a5a9;
 
}
.section1-reply{
    display: flex;
    /* width: 100%; */
    align-items: center;
    padding: 13px 23px; 
    /* flex-wrap: wrap; */
    gap: 5px;
    justify-content: space-between;
}
.reply-headings{
    line-height: 2;
}
.section-2-reply{
    padding: 20px;
}
.section-2-replytext{
    
    box-sizing: border-box;
width: 100%;
margin: 0;
height: 100px;
padding: 0 calc(16px / 2);
font-family: inherit;
font-size: 16px;
line-height: calc(16px + 16px / 2);
color: #888;
background-color: none;
border: 0;
outline: 0 !important;
resize: none;
overflow: hidden;
}
.upload-options2{
    display: flex;
    gap: 1rem;
}

.section-2-reply-1{
    padding: 20px;
}
.check-box-post-btn-s{
 
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.post-btn-dis1{
    padding: 7px 16px;
    border: 1px solid;
    color: white;
    background: #bc0000;
    border-radius: 15px;
}
.ReactModal__Overlay--after-open{
    background-color: rgb(0 0 0 / 75%) !important;
}



.acc-btn{
padding: 9px 26px;
    color: white;
    background: #bc0000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    border: none;
}
.dec-btn{
    padding: 9px 26px;
    color: white;
    background: #bc0000;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: none;
    border-right:white ;
}
.acc-dec-btn {
    display: flex;
    gap: 1px;

}
.comment-2{

    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    
  } 

.delete-btn-rr{
  padding: 8px 25px;
  color: white;
  background: #bc0000;
  border-radius: 8px;
  border: none;
}

@media only screen and (max-width: 600px) {

.comment-2 {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    flex-wrap: wrap;
}
}
.btn-d-a1 {
    display: flex;
    justify-content: space-between;
}


.acc-btn1{
background-color: #bc0000;
color: white;
padding: 10px 15px;
border-radius: 20px;
cursor:unset;
display: flex;
justify-content: center;
align-items: center;
gap: 2px;

}


.acc-btn2{
    background-color: #00bc48;
    color: white;
    padding: 10px 15px;
    border-radius: 20px;
    cursor:unset;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    
    }